<script setup lang="ts">
import { useSlots } from 'vue'
import { hasOwnProperty } from '~/src/utils'

interface Props {
  active?: boolean
}

const props = defineProps<Props>()

const slots = useSlots()

const hasIcon = hasOwnProperty(slots, 'icon')
</script>

<template>
  <div class="rounded-full w-8 h-8 flex items-center justify-center border border-black-100 relative" :class="{'opacity-40': !props.active}">
    <div class="w-7 h-7 bg-black-10 rounded-full text-subheader text-black-100 uppercase flex items-center justify-center">
      <slot />
    </div>
    <div v-if="hasIcon" class="icon w-4 h-4 bg-accent absolute rounded-full -right-1 -top-1 border border-white-100 text-white-100 flex justify-center items-center">
      <slot name="icon" />
    </div>
  </div>
</template>

<style scoped lang="postcss">
.icon {
  :deep(.nuxt-icon) {
    @apply w-10/12;

    svg {
      @apply w-full;
    }
  }
}
</style>
